toastr.options.closeButton = true;
toastr.options.hideDuration = 500;
toastr.options.newestOnTop = true;
toastr.options.progressBar = true;
toastr.options.showMethod = 'slideDown';

$('#alertas_laravel').find('p').each(function(index, alerta) {
    if($(alerta).hasClass('erro')){
        erro($(alerta).text());
    }else if($(alerta).hasClass('sucesso')){
        sucesso($(alerta).text());
    }else if($(alerta).hasClass('info')){
        info($(alerta).text());
    }else if($(alerta).hasClass('aviso')){
        aviso($(alerta).text());
    }
})

function info(texto) {
    toastr.options.extendedTimeOut = 0;
    toastr.options.timeOut = 0;
    toastr.info(texto);
}

function aviso(texto) {
    toastr.options.extendedTimeOut = 0;
    toastr.options.timeOut = 0;
    toastr.warning(texto);
}

function erro(texto) {
    toastr.options.extendedTimeOut = 0;
    toastr.options.timeOut = 0;
    toastr.error(texto);
}

function sucesso(texto) {
    toastr.options.extendedTimeOut = 3000;
    toastr.options.timeOut = 3000;
    toastr.success(texto);
}
